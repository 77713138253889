<template>
  <div id="user-security-view">
    <v-row id="user-security-information" class="pt-5 ml-4" no-gutters>
      <v-col cols="12" class="mb-12">
        <v-row class="mb-5">
          <v-col cols="12" lg="9">
            <h1 class="text-h5 secondary--font font-weight-large">Security</h1>
          </v-col>
        </v-row>
        <template v-if="isLoading">
          <v-row
            v-for="n in 3"
            :key="n"
          >
            <v-col cols="12">
              <v-skeleton-loader type="heading" width="40%" class="mb-2" />
            </v-col>
            <v-col cols="12">
              <div class="mb-3">
                <div class="w-100">
                  <v-skeleton-loader type="heading" width="30%" class="mb-2" />
                  <v-skeleton-loader type="text" class="caption mb-2" width="60%" />
                </div>
              </div>
              <v-card class="pa-4 d-flex justify-space-between mb-4">
                <v-row class="flex-wrap align-center justify-space-between">
                  <v-col class="d-flex align-center">
                    <v-skeleton-loader type="text" class="user-label" width="100px" />
                  </v-col>
                  <v-col class="text-center">
                    <v-skeleton-loader type="text" width="150px" />
                  </v-col>
                  <v-col class="d-flex justify-end">
                    <v-skeleton-loader type="button" />
                  </v-col>
                </v-row>
              </v-card>
              <v-skeleton-loader type="divider" class="mt-10 mb-8" />
            </v-col>
          </v-row>
        </template>
        <v-row v-else>
          <v-col
            v-for="(credentials, category) in credentialList"
            :key="category"
            cols="12"
          >
            <v-row>
              <v-col cols="12">
                <h1 class="subtitle-1 secondary--font font-weight-large">
                  {{ t[category] }}
                </h1>
              </v-col>
              <v-col
                v-for="credential in credentials"
                :key="credential.displayName"
                cols="12"
              >
                <div class="d-flex justify-space-between flex-wrap mb-3">
                  <div>
                    <h1 class="subtitle-2 secondary--font font-weight-large">
                      {{ t[credential.displayName] }}
                    </h1>
                    <p class="caption" v-html="t[credential.helptext]"></p>
                  </div>
                  <div>
                    <v-btn
                      v-if="shouldShowSetupButton(credential)"
                      dark
                      color="dark-black"
                      class="font-weight-bold white--text"
                      v-track="'update-password-btn'"
                      @click="onSetup(credential)"
                    >
                      <v-icon>mdi-plus</v-icon> Set up {{ t[credential.displayName] }}
                    </v-btn>
                  </div>
                </div>

                <v-card
                  v-if="!credential.userCredentialMetadatas.length"
                  class="pa-4 d-flex justify-center"
                  outlined
                >
                  No configurations available.
                </v-card>

                <v-card
                  v-for="item in credential.userCredentialMetadatas"
                  :key="item.credential.id"
                  class="pa-4 d-flex justify-space-between mb-4"
                >
                  <v-row align="center" justify="space-between" class="flex-wrap">
                    <!-- Label Section -->
                    <v-col cols="12" sm="4" class="d-flex align-center">
                      <v-icon class="mr-1">{{ `mdi-${t.icons[credential.type]}` }}</v-icon>
                      <b class="user-label">{{ item.credential.userLabel || 'My Password' }}</b>
                    </v-col>

                    <!-- Created Date -->
                    <v-col cols="12" sm="4" class="text-center">
                      <b>Created Date: </b>
                      {{ formatDateTZ( item.credential.createdDate, "MMM DD, YYYY [at] hh:mm A", timeZone) }}
                    </v-col>

                    <!-- Action Buttons -->
                    <v-col cols="12" sm="4" class="text-right">
                      <div v-for="(action, index) in getActions(credential)" :key="index">
                        <v-btn
                          v-if="action.condition"
                          dark
                          color="dark-black"
                          class="font-weight-bold white--text"
                          v-track="'update-password-btn'"
                          @click="action.handler(credential, item)"
                        >
                          {{ action.label }}
                        </v-btn>
                      </div>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
            <!-- Note: Remove two-factor check once pass key enabled -->
            <v-divider v-if="category !== 'passwordless' && category !== 'two-factor' " class="mt-10 mb-8 border-light-grey" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { getCredentials, removeCredential } from "@/services/account";
import { getUserProfile } from "@/services/users";
import { formatDateTZ } from "@/utils";

export default {
  name: "UserSecurity",
  data() {
    return {
      formatDateTZ,
      credentialList: {},
      t: this.$dictionary.app.security['sign-in'],
      isLoading: false,
      timeZone: ''
    };
  },
  async mounted() {
    try {
      this.isLoading = true;

      // Fetch user profile data from Keycloak
      const { data: result } = await getUserProfile();

      // Extract preferences attribute (if available)
      const preferences = result?.attributes?.preferences?.[0];

      // Parse preferences JSON and get the user's timezone (if available)
      this.timeZone = preferences ? JSON.parse(preferences).time_zone : 'America/New_York';

      // Fetch credentials from the server.
      const { data: credentials } = await getCredentials();

      // Group the fetched credentials by their category using `Object.groupBy`.
      // This organizes credentials into categories like 'two-factor', 'passwordless', etc.
      this.credentialList = Object.groupBy(credentials, ({ category }) => category);

      // Remove unused OTP and Recovery credentials from the 'two-factor' category.
      this.credentialList['two-factor']?.forEach(element => {
        if (element.userCredentialMetadatas.length > 1) {
          // Remove the first (oldest) credential from the list and delete it.
          this.onDirectRemove(element.userCredentialMetadatas.shift().credential);
        }
      });
    } catch (error) {
      // Handle any errors that occur during the fetching or processing of credentials.
      console.error("Error fetching credentials:", error);
    } finally {
      // Set loading state to false once the operation is complete (whether successful or not).
      this.isLoading = false;
    }
  },
  methods: {
    /**
     * Determines whether the setup button should be shown based on the credential type and existing credentials.
     * @param {Object} params - The parameters containing the credential type and user credential metadata.
     * @param {string} params.type - The type of credential (e.g., 'webauthn-passwordless').
     * @param {Array} params.userCredentialMetadatas - The list of existing user credentials.
     * @returns {boolean} - True if the setup button should be shown, otherwise false.
     */
    shouldShowSetupButton({ type, userCredentialMetadatas }) {
      return type === 'webauthn-passwordless' || !userCredentialMetadatas.length;
    },

    /**
     * Handles Keycloak actions such as login, update, or delete.
     * @param {string} action - The action to be performed (e.g., 'update', 'delete_credential').
     */
    handleKeycloakAction(action) {
      if (action) {
        this.$keycloak.login({ action, redirectUri: window.location.href });
      }
    },

    /**
     * Handles the setup action for a credential.
     * @param {Object} params - The parameters containing the create action.
     * @param {string} params.createAction - The action to create a new credential.
     */
    onSetup({ createAction }) {
      this.handleKeycloakAction(createAction);
    },

    /**
     * Handles the update action for a credential.
     * @param {Object} params - The parameters containing the update action.
     * @param {string} params.updateAction - The action to update an existing credential.
     */
    onUpdate({ updateAction }) {
      this.handleKeycloakAction(updateAction);
    },

    /**
     * Handles the delete action for a credential.
     * @param {Object} item - The credential item to be deleted.
     * @param {Object} params - The parameters containing the credential details.
     * @param {Object} params.credential - The credential object containing the ID.
     */
    onDelete(item, { credential }) {
      this.handleKeycloakAction(`delete_credential:${credential.id}`);
    },

    /**
     * Directly removes a credential by its ID.
     * @param {Object} params - The parameters containing the credential ID.
     * @param {string} params.id - The ID of the credential to be removed.
     */
    async onDirectRemove({ id }) {
      try {
        await removeCredential(id);
      } catch (error) {
        console.error("Error removing credential:", error);
      }
    },

    /**
     * Retrieves the available actions for a credential based on its type and state.
     * @param {Object} credential - The credential object.
     * @returns {Array} - An array of actions that can be performed on the credential.
     */
    getActions(credential) {
      return [
        {
          condition: credential.updateAction,
          label: "Update",
          handler: this.onUpdate,
        },
        {
          condition: credential.category === "two-factor",
          label: "Re-configure",
          handler: this.onSetup,
        },
        {
          condition: credential.type === "webauthn-passwordless",
          label: "Delete",
          handler: this.onDelete,
        },
      ];
    }
  }
};
</script>
