/**
 * Authentication Module for Managing User Credentials
 * 
 * This service provides functions to manage user credentials using authenticated API requests.
 * It uses Keycloak for authentication and interacts with the identity service to manage credentials.
 * 
 * @author Vikram Singh
 */
import { keycloakObj } from "@/utils";
import { $identity } from "@/services/apis/";

const resource = 'account';

/**
 * Helper function to create a request configuration with the base URL and authentication.
 * @returns {Object} - The request configuration object.
 */
const getRequestConfig = () => {
  const { baseURL } = keycloakObj();
  return { baseURL, withCredentials: true };
};

/**
 * Fetches user credentials from the server.
 * This function makes an authenticated GET request to the credentials endpoint.
 * 
 * @returns {Promise} - A promise that resolves to the response containing the user credentials.
 */
export const getCredentials = () => {
  const config = getRequestConfig();
  return $identity.get(`${resource}/credentials`, config);
};

/**
 * Removes a specific credential by its ID.
 * This function makes an authenticated DELETE request to the credentials endpoint.
 * 
 * @param {string} credentialId - The ID of the credential to be removed.
 * @returns {Promise} - A promise that resolves to the response of the delete operation.
 */
export const removeCredential = (credentialId) => {
  const config = getRequestConfig();
  return $identity.delete(`${resource}/credentials/${credentialId}`, config);
};
